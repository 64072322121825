export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const GET_USER = "GET_USER";
export const GET_ALL_USER = "GET_ALL_USER";
export const PATCH_USER_ACTIVE_BRANCH = "PATCH_USER_ACTIVE_BRANCH";
export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const POST_CREATE_USER = "POST_CREATE_USER";

export const GET_METADATA = "GET_METADATA";

export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";

export const GET_SEARCH_CUSTOMER = "GET_SEARCH_CUSTOMER";
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID";
export const POST_CREATE_CUSTOMER = "POST_CREATE_CUSTOMER";

export const POST_CREATE_PAWN_TICKET = "POST_CREATE_PAWN_TICKET";
export const GET_ALL_PAWN_TICKETS = "GET_ALL_PAWN_TICKETS";
export const GET_PAWN_TICKET_BY_ID = "GET_PAWN_TICKET_BY_ID";
export const GET_CALCULATE_MONTHLY_INTEREST = "GET_CALCULATE_MONTHLY_INTEREST";
export const POST_DRAFT_TICKET_INVOICE = "POST_DRAFT_TICKET_INVOICE";
export const GET_TICKET_INVOICE = "GET_TICKET_INVOICE";
export const GET_REVISION_IDS = "GET_REVISION_IDS";
export const POST_CREATE_REVISION = "POST_CREATE_REVISION";
export const PATCH_UPDATE_INVOICE = "PATCH_UPDATE_INVOICE";
export const PATCH_UPDATE_PAWN_TICKET_GENERAL =
  "PATCH_UPDATE_PAWN_TICKET_GENERAL";

export const GET_ITEMS_BY_PAWN_TICKET = "GET_ITEMS_BY_PAWN_TICKET";
export const DELETE_ITEM = "DELETE_ITEM";
export const POST_ADD_ITEM = "POST_ADD_ITEM";
export const PATCH_ITEM = "PATCH_ITEM";

export const GET_INTERETS_BY_PAWN_TICKET = "GET_INTERETS_BY_PAWN_TICKET";

export const GET_TICKET_STATUS_STATS = "GET_TICKET_STATUS_STATS";

export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const POST_CREATE_ROLE = "POST_CREATE_ROLE";

export const GET_ALL_FUNCTIONS = "GET_ALL_FUNCTIONS";
